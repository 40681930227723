/*** Core ***/
import styled from 'styled-components'
import React, { useState } from 'react'
/*** Components ***/
import Container from '../Container'
import Form from '../../components/form/Form'
/*** Globals ***/
import Color from '../../commons/Color'
import Montserrat from '../../commons/mixins/Montserrat'
/*** Assets ***/
import Belen from '../../assets/images/team/jenny.jpg'
import CalendarApp from '../../components/calendar/Calendar'
import Paragraph from '../../components/texts/Paragraph';

/*----- Styles -----*/
const StyledContact = styled.div`
  background-color: white;
  .greeting-container {
    .greeting {
      .photo {
        width: 100px;
        border-radius: 50%;
      }
      h2 {
        font-family: 'Pacifico', cursive;
        font-size: 20px;
      }
      h3 {
        ${Montserrat(30, 600, Color.main)}
        line-height: 32px;
        max-width: 300px;
      }
    }
  }
  .form-box {
    width: 100%;
    max-width: 320px;
  }
`

/*----- Component -----*/
export default function Contact() {
  const [day, setDay] = useState({day: "Día no seleccionado"})
  const [time, setTime] = useState({value:"Hora no seleccionada"})
  return (
    <Container>
      <StyledContact className="container-fluid">
        {/* Video section */}
          <div className="row">
            <div className="col-12 greeting-container">
              <div className="greeting pt-5 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <img src={Belen} alt="profile pic" className="photo" />
                <div className="pl-0 pl-lg-4 mt-4 mt-lg-0">
                  <h2>Hola, soy Jenny!</h2>
                  <h3>
                    Puedo ayudarte en este proceso.
                  </h3>
				  <div className="mt-3">
				  	<Paragraph content="Queremos conocer cuáles son tus necesidades y contarte cómo trabajamos."/>
				  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12 col-md-6 form-container mt-5">
              <div className="form-container d-flex flex-column align-items-center justify-content-center">
                <div className="form-box">
                  <Form day={day} time={time}/>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start form-container mt-5">
              <div className="form-box">
                <CalendarApp dateHandler = {{setDay, setTime}}/>
              </div>
            </div>
          </div>
      </StyledContact>
    </Container>
  )
}
