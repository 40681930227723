/*** Core ***/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
/*** Mixins ***/
import Montserrat from '../../commons/mixins/Montserrat'

/*----- Styles -----*/
const StyledHero = styled.article`
  width: 100%;
  max-width: 600px;
  .title {
    ${ Montserrat(80, 700, 'white') }
    margin: 0;
    font-size: 46px;
    line-height: 46px;
  }
  .subtitle {
    ${ Montserrat(20, 300, 'white') }
    max-width: 450px;
  }
  /* Media queries */
  @media (min-width: 767px) {
    .title {
      font-size: 80px;
      line-height: 76px;
    }
  }
`

/*----- Component -----*/
const Hero = ({ title, subtitle }) => (
  <StyledHero className="hero">
    <h1 className="title">{ title }</h1>
    { subtitle ? (<h2 className="subtitle my-4">{ subtitle }</h2>) : null }
  </StyledHero>
)
export default Hero

/*----- PropTypes -----*/
Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
}
