/*** Core ***/
import React from 'react'
import styled from 'styled-components'
/*** Mixins ***/
import MontSerrat from '../../commons/mixins/Montserrat'

const StyledEmailAddress = styled.a`
  ${ MontSerrat(20, 600, 'black') }
  @media (min-width: 991px) {
    font-size: 30px;
  }
`

const EmailAddress = ({ content }) => (
  <StyledEmailAddress href={`mailto:${content}`} target="_blank">
    { content }
  </StyledEmailAddress>
)
export default EmailAddress
