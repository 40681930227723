
/*** Core ***/
import React from 'react'
import styled from 'styled-components'
/*** Assets ***/
import Logo from '../../assets/logos/mei_logo_original_white.svg'
/*** Components ***/
import NavBtn from '../../components/buttons/NavBtn'

const StyledHeader = styled.header `
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  height: 80px;
  .logo {
    width: 180px;
  }
  .selected {
    font-weight: 600;
  }
  .item-container {
	background: #ffffff;
    height: 40px;
    border-radius: 30px;
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.5);
  }

`

const Header = (props) => {
  return(
    <StyledHeader className="d-flex justify-content-center justify-content-md-between align-items-center px-3">
    <img src={Logo} className="logo" alt="logo"/>
    <div className="item-container d-flex align-items-center">
      <NavBtn
        content     = "Agencia"
        destination = "/agency"
      />
      <NavBtn
        content     = "Contacto"
        destination = "/contact"
      />
    </div>
  </StyledHeader>
  )
}
export default Header
