/*** Core ***/
import React from 'react'
import styled from 'styled-components'
/*** Components ***/
import Container from '../Container'

const StyledUs = styled.div`
  height: 100vh;
  h1 {
    color: white;
  }
`

export default function Us() {
  return (
    <Container>
      <StyledUs>
        {/* Video section */}
        <div className="container-fluid">
          <h1>Nosotros</h1>
        </div>
      </StyledUs>
    </Container>
  )
}
