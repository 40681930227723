/*** Core ***/
import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
/*** Styles ***/
import 'bootstrap/dist/css/bootstrap.css'
/*** Containers ***/
import Agency from './modules/agency/Agency'
import Us from './modules/us/Us'
import Contact from './modules/contact/Contact'
/*** Components ***/
import Header from './commons/nav/Header'
import Footer from './commons/nav/Footer'

export default function App() {
  return (
    <Router>
      <Header />
      {/*** START Routes ***/}
      <Switch>
        <Route exact path="/" render={() => (<Redirect to="/agency"/>)} />
        <Route exact path="/agency" component={ Agency } />
        <Route exact path="/us" component={ Us } />
        <Route exact path="/contact" component={ Contact } />
      </Switch>
      {/*** END Routes ***/}
      <Footer />
    </Router>
  )
}
