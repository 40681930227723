/*** Core ***/
import React from 'react';
import styled from 'styled-components'
import { css } from 'styled-components'
/*** Commons ***/
import Montserrat from '../../commons/mixins/Montserrat'
import Color from '../../commons/Color'

const StyledActionBtn = styled.button`
  border-radius: 50px;
  padding: 10px 30px;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  ${ Montserrat(16, 600, 'white') };
  font-weight: 700;
  background: ${Color.main};
  color: white;
  min-width: 115px;
  text-align: center;
  display: block;
  max-width: 200px;
  border: none;

  &:hover {
    text-decoration: none;
    color: white;
  }

  ${props => props.invert && css`
    background: white;
    color: ${ Color.main };
    &:hover {
      color: ${ Color.main };
    }
  `}
`

const ActionBtn = ({ content, onClick, invert }) => {
  return(
    <StyledActionBtn className="p-2" invert={invert} onClick={onClick}>
      { content }
    </StyledActionBtn>
  )
}
export default ActionBtn
