/*** Core ***/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
/*** Commons ***/
import Device from '../../commons/mixins/Device'
import Montserrat from '../../commons/mixins/Montserrat'
/*** Components ***/
import Video from '../../components/Video'
/*----- Styles -----*/
const StyledServiceCard = styled.article`
  height: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .title {
    ${ Montserrat(12, 600, 'black') }
    margin: 0;
    line-height: 32px;
  }
  .subtitle {
    ${ Montserrat(30, 300, 'black') }
    max-width: 400px;
  }
  
  .info-container {
		background: rgba(255, 255, 255, 0.8);
		min-height: -webkit-fill-available;
		opacity: 1;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
    }
  .image-container, .video-container {
    transform: scale(1.1);
    transition: all 0.8s;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
  }
  &:hover {
    text-decoration: none;
    .info-container {
      opacity: 1;
      bottom: 0;
    }
    .image-container, .video-container {
      transform: scale(1);
    }
  }
  &:hover {
    .info-container {
      background: rgba(255, 255, 255, 0.8);
      h3 {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  	@media ${Device.lg} {
		width: 50%;
		.info-container {
			transition: all 0.5s;
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: 1;
			opacity: 0;
			.subtitle {
				transition: all 0.3s;
				opacity: 0;
				transform: scale(0.8);
			}
		}
	}
`

/*----- Component -----*/
const ServiceCard = ({ title, subtitle, imgUrl, destination, videoUrl, coverOpacity, coverColor }) => (
  <StyledServiceCard to={ destination } className="service-card d-flex flex-column justify-content-end">
    <div className="info-container d-flex flex-column justify-content-start p-4">
      <h2 className="title">{ title }</h2>
      { subtitle ? (<h3 className="subtitle">{ subtitle }</h3>) : null }
    </div>
    {
      videoUrl? (
        <Video
          videoUrl     = { videoUrl }
          coverOpacity = { coverOpacity }
          coverColor   = { coverColor }
        />
      ):(
        <div className="image-container" style={{backgroundImage: `url(${imgUrl})`} }></div>
      )
    }
  </StyledServiceCard>
)
export default ServiceCard

/*----- PropTypes -----*/
ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  imgUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  colorOpacity: PropTypes.number,
  coverColor: PropTypes.string
}
