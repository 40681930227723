/*** Core ***/
import React from 'react'
import styled from 'styled-components'
/*** Mixins ***/
import MontSerrat from '../../commons/mixins/Montserrat'

const StyledPhoneNumber = styled.a`
  ${ MontSerrat(30, 900, 'black') }
  @media (min-width: 991px) {
    font-size: 40px;
  }
`

const PhoneNumber = ({ content }) => (
  <StyledPhoneNumber href={`tel: ${ content }`}>
    { content }
  </StyledPhoneNumber>
)
export default PhoneNumber
