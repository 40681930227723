// Core
import React from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label`
  color: black;
  font-weight: 600;
  font-size: 12px;
  margin: 0;
`

const Label = ({ value }) => (
  <StyledLabel value={ value } className="pl-2"> { value } </StyledLabel>
)
export default Label
