export default () => {
  return `
    padding: 10px 0 10px 0;
    color: black;
    background: none;
    border: none;
    border-bottom: 1px solid #cccccc;
    outline: none;
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    &::placeholder {
      color: lightgrey;
      font-size: 16px;
    }
  `
}
