/*** Core ***/
import React from 'react'
import styled from 'styled-components'
/*** Components ***/
import Container from '../Container'
import Hero from '../../components/texts/Hero'
import Video from '../../components/Video'
import ServiceCard from '../../components/cards/ServiceCard'
import ServicesVideo from '../../assets/videos/humo.mp4'
import MeiInternaVideo from '../../assets/videos/mei_interna.mp4'
import PrimaryBtn from '../../components/buttons/PrimaryBtn'
/*** Assets ***/
import brandStrategyServiceImage from '../../assets/images/services/brand-strategy.png'
import technologyServices from '../../assets/images/services/technology.png'
import socialNetworks from '../../assets/images/services/social_networks.png'
import designCommunication from '../../assets/images/services/design-communication.png'
import video from '../../assets/videos/templ-team.mp4'

/*----- Styles -----*/
const StyledHome = styled.section`
  width: 100%;
  .hero-container {
    z-index: 3;
    height: 100vh;
    .call-to-action {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .hero {
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
    }
  }
  .default-title {
    border-bottom: 1px solid white;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`
const HomeVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

/*----- Component -----*/
export default function Home() {
  return (
    <Container>
      <StyledHome>
        <div className="container d-flex justify-content-center align-items-start flex-column">
          <div className="hero-container">
            <div className="call-to-action">
              <Hero
                title    = "Somos una agencia creativa."
                subtitle = "¿Pensaste en desarrollar una estrategia de comunicación y diseño para alcanzar los objetivos de tu empresa? Estás en el lugar correcto."
              />
              <PrimaryBtn content="Comencemos" destination="/contact" white="true"/>
            </div>
          </div>
          <HomeVideo>
            <Video
              videoUrl      = { ServicesVideo }
              coverOpacity  = { 0.8 }
              coverColor    = "#ff28db"
            />
          </HomeVideo>
        </div>
        <div className="container-fluid">
          <div className="row">
            <ServiceCard
              title       = "Branding"
              subtitle    = "Generamos una experiencia de marca distintiva, relevante, completa y sostenible en el tiempo."
              videoUrl      = { video }
              coverOpacity = { 0.2 }
              coverColor   = "#FFFFFF"
            />
			<ServiceCard
              title        = "Mei Outside"
              subtitle     = "Desarrollamos una estrategia y planificamos la comunicación externa para construir un intercambio efectivo y fluido con tus públicos de interés."
              imgUrl     = { designCommunication }
            />
            <ServiceCard
              title       = "Soluciones informáticas"
              subtitle    = "Trabajamos en conjunto con nuestro partner Intiva para desarrollar sitios web, aplicaciones móviles  y plataformas a medida."
              imgUrl      = { technologyServices }
            />
			<ServiceCard
              title        = "Mei Inside"
              subtitle     = "Planificamos y gestionamos la comunicación interna para maximizar el rendimiento de los equipos de trabajo y la cultura organizacional."
              videoUrl     = { MeiInternaVideo }
              coverOpacity = { 0.5 }
			  coverColor   = "#ffffff"
            />
            <ServiceCard
              title        = "Marketing digital"
              subtitle     = "Creamos estrategias para definir el posicionamiento de nuestros clientes y abordamos cada proyecto desde una visión global."
              imgUrl       = { brandStrategyServiceImage }
            />
            <ServiceCard
              title        = "Social media"
              subtitle     = "Gestionamos la presencia de tu empresa o entidad en las redes sociales. Generamos contenido creativo, que humanice tu marca, y construimos una comunidad entorno a ella."
              imgUrl       = { socialNetworks }
            />
          </div>
        </div>
      </StyledHome>
    </Container>
  )
}
