import React from 'react';
import styled from 'styled-components'
import InputStyles from '../../commons/mixins/InputStyles'


const StyledTextArea = styled.textarea `
  ${InputStyles()};
  height: 209px;
`

const Textarea = ({ placeholder, value, name, onChange }) => (
  <StyledTextArea
    placeholder = { placeholder }
    name        = { name }
    onChange    = { (e) => onChange(e) }
    className   = "contact-input pl-2"
  />
)
export default Textarea
