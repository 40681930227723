/*** Core ***/
import React from 'react'
import styled from 'styled-components'
/*** Commons ***/
import montserrat from '../../commons/mixins/Montserrat'
/*** Assets ***/
import MeiMap from '../../assets/images/mei_location.png'
/*** Components ***/
import Paragraph from '../../components/texts/Paragraph'
import Logo from '../../assets/logos/mei_logo_original_black.svg'
import PhoneNumber from '../../components/texts/PhoneNumber'
import EmailAddress from '../../components/texts/EmailAddress'

const StyledFooter = styled.footer`
  .brand {
    img {
      width: 300px;
    }
  }
  .contact {
    .contact-item {
      ${ montserrat(24, 600, 'black')}
    }
  }
  .location {
    .map {
      width: 200px;
      border-radius: 50%;
    }
    p {
      margin: 0 !Important;
    }
  }
  .rights {
    p {
      ${ montserrat(14, 500, 'black')};
      margin: 0;
    }
  }
  @media (min-width: 767px) and (max-width: 991px) {
    .contact {
      .contact-item {
        ${ montserrat(18, 600, 'black')}
      }
    }
  }
`

const Footer = () => (
  <StyledFooter className = "container py-5">
    <div className="row">
      <div className="col-12 col-md-6 d-flex flex-column my-5">
        <div to="/" className="d-block brand mb-3">
          <img src={Logo} alt="logo" />
        </div>
        <div className="d-flex flex-column">
          <div className="my-3">
            <EmailAddress content="info@mei-agency.com" />
          </div>
          <PhoneNumber content="+54 9 0221 593 7866" />
          <PhoneNumber content="+54 9 0221 304 4480" />
        </div>
      </div>
      <div className="map col-12 col-md-4 d-flex flex-column align-items-center location my-5">
        <img src={ MeiMap } className="map" alt="map"/>
        <div className="d-flex flex-column mt-4">
          <Paragraph content="La Plata, Buenos Aires, Argentina."/>
          <Paragraph content="Calle 8 nº 128 e/ 34 y 35."/>
        </div>
      </div>
    </div>
    <div className="row rights mb-5">
      <p className="col-12 text-center">
        © {new Date().getFullYear()} Mei Agency. All rights reserved.
      </p>
    </div>
  </StyledFooter>
)
export default Footer
