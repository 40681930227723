import React, { Component } from 'react'
import styled from 'styled-components'
// Components
import Input from './Input'
import Textarea from './Textarea'
import Label from './Label'
import ActionBtn from '../buttons/ActionBtn'

const StyledForm = styled.form`
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 6px;

  form {
    background-color: white;
    border-radius: 6px;
    position: absolute;
    z-index: 2;
  }
  .message-box {
    opacity: 0;
    display: none;
    padding: 8px 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  #message-box-ok {
    background: #73bb52;
    color: white;
  }
  #message-box-not-ok {
    background: #ff418c;
    color: white;
  }
  #validation-error {
    background: #ff418c;
    color: white;
  }
  .message-box-visible {
    transition: all 1s;
    display: flex !important;
    opacity: 1;
  }
  @media (max-width: 767px) {
    form {
      max-width: 350px;
    }
  }
`

class Form extends Component {
  constructor() {
    super()
    this.state = {
      name: '',
      email: '',
      message:'',
      subject: ''
    }
    this.handleMailData  = this.handleMailData.bind(this)
  }

  handleMailData = (e) => {
    let target = e.target.name
    let value = e.target.value
    this.setState({
      [target]: value
    })
  }

  showMessage = (el) => {
    el.classList.add("message-box-visible")
    setTimeout(function () {
      el.classList.remove("message-box-visible")
    }, 3000);
  }

  validateEmail = (email) => {
    const re = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/
    return re.test(email);
  }

  validateName = (name) => {
    const re = /^[a-zA-Z ]{2,30}$/
    return re.test(name);
  }

  validatePhone= (phone) => {
    const re = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
    return re.test(phone);
  }

  sendEmail = (e) => {
    e.preventDefault()
    let name = this.validateName(this.state.name) ? this.state.name : '';
    let email = this.validateEmail(this.state.email) ? this.state.email : '';
    let message = this.state.message;
    if (!email || !name) {
      this.showMessage(document.getElementById('validation-error'))
    } else {
      fetch(process.env.REACT_APP_API_MESSAGE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token token='+process.env.REACT_APP_API_MESSAGE_TOKEN
        },
        mode: 'cors',
        body: JSON.stringify({
          sender: name,
          sender_email: "intiva-web@snappler.com", //poner el lista de permitidos
          recipients: ["info@mei-agency.com"],
          reply_to: `${email}`,
          subject: "Consulta desde Mei beb",
          template_name: "mensajes-simple",
          template_params: {
            "header": "Nueva consulta desde la web mei",
			"body": ` <p>Asunto: ${this.state.subject}</p>
						<p>Dia para call: ${this.props.day.day}</p>
                    	<p>Hora para call: ${this.props.time.value}</p>
                        <p>Email de: ${email}:</p>
                        <p>Mensaje: ${message}</p>`
          }
        })

      }).then(function (response) {
        if (response.ok) {
          this.showMessage(document.getElementById('message-box-ok'))
          this.clear()
        }
        else {
          this.showMessage(document.getElementById('message-box-not-ok'))
        }
      }.bind(this))
    }
  }

  clear = () => {
    const items = document.getElementsByClassName('contact-input')
    const itemsArr = Array.from(items)
    itemsArr.map((el) => {
      return el.value = ''
    })
  }

  render() {
	  console.log(this.state)
    return (
      <StyledForm className="flex-column">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-column mb-3">
            <Label value="name" />
            <Input
              type        = "name"
              name        = "name"
              placeholder = "Company name"
              value       = {this.state.name}
              onChange    = {this.handleMailData}
            />
          </div>
          <div className="d-flex flex-column my-3">
            <Label value="email" htmlFor="email" />
            <Input
              type        = "email"
              name        = "email"
              placeholder = "Company email"
              value       = {this.state.email}
              onChange    = {this.handleMailData}
            />
          </div>
          <div className="d-flex flex-column my-3">
            <Label value="subject" htmlFor="subject" />
            <Input
              type        = "subject"
              name        = "subject"
              placeholder = "Company subject"
              value       = {this.state.subject}
              onChange    = {this.handleMailData}
            />
          </div>
          <div className="d-flex flex-column">
            <Label value="message" />
            <Textarea
              name        = "message"
              placeholder = "Write your message here"
              onChange    = {this.handleMailData}
            />
          </div>
          <div className="d-flex align-items-center mt-3">
            <div className="ml-3 message-box" id="message-box-ok">Message sent</div>
            <div className="ml-3 message-box" id="message-box-not-ok">Couldn't send the mail please write to us at info@intiva-it.com</div>
            <div className="ml-3 message-box" id="validation-error">Invalid name or email</div>
          </div>
        </div>
        <div className="pt-4">
          <ActionBtn content="send" onClick={ this.sendEmail } />
        </div>
      </StyledForm>
    )
  }
}

export default Form;
