/*** Core ***/
import React from 'react'
import styled from 'styled-components'

const StyledVideo = styled.div `
  max-height: auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 2;
  }
  video {
    position: absolute;
    z-index: 1;
    min-width: 100%;
    top: 50%;
    left: 50%;
    max-width: inherit;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    max-width: inherit;
  }
`
const Video = ({ height, width, videoUrl, coverOpacity, coverColor }) => (
    <StyledVideo style={{height: height, width: width}} className="video-container">
      <div className="cover" style={{opacity: coverOpacity, background: coverColor }}></div>
      <video preload="true" autoPlay="autoplay" loop="loop" muted="muted">
        <source src={ videoUrl } type="video/mp4"></source>
      </video>
    </StyledVideo>
)
export default Video
