/*** Core ***/
import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
/*** Commons ***/
import Montserrat from '../../commons/mixins/Montserrat'
import Color from '../../commons/Color'


const StyledPrimaryBtn = styled(NavLink)`
  ${ Montserrat(16, 600, 'black') };
  cursor: pointer;
  background: ${Color.main};
  border-radius: 30px;
  width: fit-content;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  max-height: 44px;
  &:hover {
    text-decoration: none;
    color: black;
  }
  ${props => props.white && css`
    background: white
    color: ${Color.main};
  `}
`
const PrimaryBtn = ({ content, destination, white }) => (
  <StyledPrimaryBtn to={ destination} white={ white }>
    { content }
  </StyledPrimaryBtn>
)
export default PrimaryBtn
