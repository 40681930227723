import styled from 'styled-components'

export default styled.main`
  position: relative;
  width: 100%;
  min-height: 100vh;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
