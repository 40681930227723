/*** Core ***/
import React from 'react';
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
/*** Commons ***/
import Montserrat from '../../commons/mixins/Montserrat'


const StyledNavBtn = styled(NavLink) `
  ${ Montserrat(14, 300, 'black') };
  text-transform: capitalize;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
    color: black;
  }
`
const NavBtn = ({ content, destination }) => (
  <StyledNavBtn
    className       = "px-3 px-md-4"
    content         = { content }
    to              = { destination }
    activeClassName = "selected"
    >
      { content }
  </StyledNavBtn>
)
export default NavBtn

/*----- PropTypes -----*/
NavBtn.propTypes = {
  content: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
}
