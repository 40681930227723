import React from 'react';
import styled from 'styled-components'
import inputStyles from '../../commons/mixins/InputStyles'

/*----- Styles -----*/
const StyledInput = styled.input `
  ${inputStyles()};
`
/*----- Component -----*/
const Input = ({ type, value, id, name, placeholder, onChange }) => (
  <StyledInput
    id          = { `form-${name}` }
    type        = { type }
    name        = { name }
    value       = { value }
    placeholder = { placeholder }
    onChange    = { (e) => onChange(e) }
    className   = "contact-input p-2"
  />
)
export default Input

/*----- PropTypes -----*/
// Input.propTypes = {
//   title: PropTypes.string.isRequired,
//   subtitle: PropTypes.string.isRequired,
//   destination: PropTypes.string.isRequired,
//   imgUrl: PropTypes.string,
//   videoUrl: PropTypes.string,
//   colorOpacity: PropTypes.number,
//   coverColor: PropTypes.string
// }
