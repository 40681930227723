/*** Core ***/
import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'
import PropTypes from 'prop-types'
/*** Mixins ***/
import Monserrat from '../../commons/mixins/Montserrat'

/*----- Styles -----*/
const StyleParagraph = styled.p`
  max-width: 450px;
  ${ Monserrat(16, 400, 'black') }
  ${props => props.small && css`
    font-size: 12px !important;
  `}
`

/*----- Component -----*/
const Paragraph = ({ content, small }) => (
  <StyleParagraph small={ small }>{ content }</StyleParagraph>
)
export default Paragraph

/*----- PropTypes -----*/
Paragraph.propTypes = {
  content: PropTypes.string.isRequired,
  small: PropTypes.bool
}
