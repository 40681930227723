/*** Core ***/
import React, { Component } from 'react'
import styled from 'styled-components'
import Calendar from 'react-calendar'
import Select from 'react-select';
 /*** Commons ***/
 import Color from '../../commons/Color'
 /*** Components ***/
import Label from '../../components/form/Label'
/*----- Styles -----*/
const StyledCalendarContainer = styled.article`

`
const selectCustomStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    background: state.isSelected ? Color.main : 'white'
  })
}

const options = [
  { value: '08:00hs - 10:00hs', label: 'Entre 08:00hs y 10:00hs' },
  { value: '10:00hs - 12:00hs', label: 'Entre 10:00hs y 12:00hs'},
  { value: '14:00hs - 16:00hs', label: 'Entre 14:00hs y 16:00hs'},
  { value: '16:00hs - 18:00hs', label: 'Entre 16:00hs y 18:00hs'}
];

export default class CalendarApp extends Component {
  state = {
    day: new Date()
  }

  onDateChange = day => {
    this.setState({ day })
    this.props.dateHandler.setDay({day})
  }

  handleTime = time => {
    this.setState({ time })
    this.props.dateHandler.setTime({...time})
  }

  render() {
    return (
      <StyledCalendarContainer>
        <div className="pb-3">
          <div className="pb-2">
            <Label value="Momento del día" />
          </div>
          <Select
            onChange    = { this.handleTime }
            options     = { options }
            placeholder = "Selecciona un horario"
            styles      = { selectCustomStyles }
          />
        </div>
        <div className="pb-3">
          <div className="py-2">
            <Label value="Día de la semana" />
          </div>
          <Calendar
            onChange = { this.onDateChange }
            value    = { this.state.day }
            locale   = "es"
          />
        </div>
      </StyledCalendarContainer>
    )
  }
}
